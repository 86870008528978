import React from "react";
import "./App.css";
import Soundcloud from "./Soundcloud";

function Music() {
  console.log("if u see this ur cool");
  return (
    <div className={"content"}>
      <div className={"title"}>MUSIC</div>
      <Soundcloud />
    </div>
  );
}

export default Music;
