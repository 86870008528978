import React from 'react';

const playlist = `
https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/
playlists/680011833&color=%23ff5500&auto_play=true&hide_related=false&
show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true
`

const Soundcloud = () => {
  return (
    <div className='music-container'>
      <iframe title={'sc'} width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" 
        src={playlist}/>
    </div>
  );
}

export default Soundcloud;
