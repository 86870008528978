import React from "react";
import "./App.css";

function Music() {
  return (
    <div className={"content"}>
      <div className={"title"}>poo</div>
    </div>
  );
}

export default Music;
