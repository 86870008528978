import React from "react";
import "./App.css";
import Music from "./Music";
import Poo from "./Poo";
import { HashRouter, Route } from "react-router-dom";

function App() {
  return (
    <HashRouter basename={"/"}>
      <Route exact path="/" component={Music} />
      <Route path="/poo" component={Poo} />
      <Route path="/" component={Music} />
    </HashRouter>
  );
}

export default App;
